import React from "react"
import Layout from "../components/layout"

import SEO from "../components/seo"
import ContactForm from "../components/ContactForm";
import bugFix from "../images/svg/undraw_bug_fixing.svg";
import progressiveApp from "../images/svg/undraw_progressive_app.svg";

const IndexPage = () => (
  <Layout>
    <SEO title="Etusivu" />
    <div className="index-page content">
        <h2>Hei, tervetuloa!</h2>


        <div className="columns">
            <div className="column">
                <p>Hienoa, että löysit sivustolle!</p>
                <p>SoftTile Oy on tällähetkellä yhden miehen show ja toimin tällähetkellä niinsanotusti freelanserina. Teen koodia suoraan ja lisäksi alihankintana kaikille asiakkaille.</p>
                <h5 style={{ color: '#f36f21', lineHeight: '1.7rem' }}>Tarvitsetko verkkosivut yrityksellesi tai vaikkapa verkkomaksamisen integrointia nykyiseen palveluusi?</h5>
                <p>Vai tarvitsetko yrityksellesi elintärkeän tiedon tallentamisen Excelistä turvallisempaan paikkaan ja helpommin käytettäväksi?</p>
            </div>
            <div className="column animate__animated animate__fadeInRight">
                <img src={progressiveApp} alt={'code'}/>
            </div>
        </div>
        <div className="columns">
            <div className="column animate__animated animate__fadeInLeft">
                <img src={bugFix} alt={'code'}/>
            </div>
            <div className="column">
                <ContactForm/>
            </div>
        </div>



    </div>
  </Layout>
)

export default IndexPage
